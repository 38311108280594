import React from "react";
import "./Video.css"
const Video = () => {
  const youtubeUrl = "https://www.youtube.com/watch?v=0VQmIzKQdFo";
  const embedUrl = `https://www.youtube.com/embed/${youtubeUrl.split("=")[1]}`; // Extract video ID

  return (
    <div className="video-container">
      <iframe
     
        src={embedUrl}
        title="Pro team "
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;
