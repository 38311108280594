import React from "react";
import "./Join.css";
const Join = () => {

    // 1.36.14

   // const form = useRef();
  return (
    <div className="join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span> READY TO</span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span> YOUR BODY</span>
          <span> WITH US?</span>
        </div>
      </div>
      <div className="right-j">
        <form action="" className="email-container">
            <input type="email" name="user_email" placeholder="Enter Your Email address"/>
          <button className="btn-default btn-j"> join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
