import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";


const Hero = () => {

// const mobile = window.innerWidth<=768 ? true: false
  return (
    <div>
      <div className="hero" id="home">
        <div className="blur hero-blur"></div>
        <div className="left-h">
          <Header />
          {/* the best ad */}
          <div className="the-best-ad">
            <div className=""></div>
            <span>the best personal trainers in the middle east</span>
          </div>
          {/* Hero Heading */}
          <div className="hero-text">
            <div>
              <span className="stroke-text">Tone </span>
              <span> your </span>
            </div>
            <div>
              <span>muscles</span>
            </div>
            <div>
              <span>
                The Middle East's first biomechanics training revolution! Craft
                your dream workout, professionally.{" "}
              </span>
            </div>
          </div>
          {/* figures */}
          <div className="figures">
            <div>
              <span>+7 years</span>
              <span>expert coachs</span>
            </div>
            <div>
              <span>+100</span>
              <span>Empowerd trainees</span>
            </div>
            <div>
              <span>custom</span>
              <span>fitness programs</span>
            </div>
          </div>
          {/* hero buttons */}
          <div className="hero-buttons">
            <buttons className="btn-default">Get Started</buttons>
            <buttons className="btn-default">Learn More</buttons>
          </div>
        </div>
        <div className="right-h">
          <button className="btn-default btn-join"> Join Now</button>
          <div className="heart-rate">
            <img src={Heart} alt="" />
            <span>Heart Rate</span>
            <span> 125 bpm</span>
          </div>

          {/* hero images */}
          <img src={hero_image} alt="" className="hero-image" />
          <img src={hero_image_back} alt="" className="hero-image-back" />
          {/* calories */}
          <div className="calories">
            <img src={Calories} alt="" />
            <div>
              <span>Calories Burned</span> 
              <span>220 kcal</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
